import { useTranslation } from "react-i18next";
import styled from "styled-components";

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  padding: 2rem;

  background-color: var(--color-background-dark);

  @media screen and (min-width: 48em) {
    flex-direction: row;
    justify-content: space-between;
    padding: 2rem 4rem;
  }

  @media screen and (min-width: 62em) {
    padding: 2rem 6rem;
  }

  @media screen and (min-width: 75em) {
    padding: 2rem 8rem;
    background-color: var(--color-background);
  }
`;

const FooterText = styled.p`
  font-size: var(--fs-100);
  color: var(--color-text-dark);
  text-align: center;

  @media screen and (min-width: 48em) {
    text-align: left;
  }
`;

const Copyright = styled.span`
  font-size: var(--fs-100);
  color: var(--color-text-dark);
  font-weight: 500;
`;

const Footer = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <FooterContainer>
      <FooterText>{t("footer.rights")}</FooterText>
      <Copyright>
        © 2022-{currentYear} Cecidatri Interiores
      </Copyright>
    </FooterContainer>
  );
};

export default Footer;
