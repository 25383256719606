import { hero } from "../assets/images/index";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const HeroContainer = styled.div`
  min-height: 100vh;
  width: 100%;

  display: grid;
  place-items: center;

  background-image: url(${hero});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const HeaderContainer = styled.div`
  height: 100%;
  width: 100%;

  display: grid;
  place-items: center;

  background-color: var(--base-background-filter);

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.25rem;

    width: "fit-content";

    padding: 0 2rem;

    h1 {
      padding: 0 3rem;
    }

    hr {
      width: 100%;
    }

    @media screen and (max-width: 36em) {
      h1 {
        padding: 0;
      }
    }
  }
`;

const Hero = () => {
  const { t } = useTranslation();
  return (
    <HeroContainer id="hero">
      <HeaderContainer>
        <div>
          <h1>{t("header")}</h1>
          <hr />
          <h3>{t("subheader")}</h3>
        </div>
      </HeaderContainer>
    </HeroContainer>
  );
};

export default Hero;
