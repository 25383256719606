import styled from "styled-components";
import {
  AnchorScrollToPosition,
  TitleContainer,
} from "../assets/styled-components/global";

import {
  project1,
  project2,
  project3,
  project4,
  project5,
  project6,
} from "../assets/images/index";
import { Trans, useTranslation } from "react-i18next";

const ProjectsContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 2rem;

  width: 100%;

  background: var(--color-background);

  @media screen and (min-width: 48em) {
    gap: 6rem;
  }
`;

const Content = styled.div`
  position: relative;
  min-height: 70vh;
  width: 100%;

  display: grid;
  place-items: center;

  p {
    color: var(--color-text-light);
    line-height: 1.4;
    font-size: var(--fs-300);
    text-align: center;
    max-width: 55ch;

    padding: 4rem 3.5rem;
    z-index: 2;
  }

  .img__filter {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

  .img_cont {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;

    width: 100%;
    height: 100%;

    display: block;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    -webkit-animation: fade 24s infinite;
    animation: fade 24s infinite;
  }

  .image_3,
  .image_4,
  .image_5 {
    display: none;
  }

  .image_0 {
    display: block;
    background-image: url(${project1});
    animation-delay: 0s;
  }

  .image_1 {
    display: block;
    background-image: url(${project2});
    animation-delay: 8s;
  }

  .image_2 {
    display: block;
    background-image: url(${project3});
    animation-delay: 16s;
  }

  @-webkit-keyframes fade {
    0% {
      opacity: 1;
    }
    20% {
      opacity: 1;
    }
    34% {
      opacity: 0;
    }
    88% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fade {
    0% {
      opacity: 1;
    }
    20% {
      opacity: 1;
    }
    34% {
      opacity: 0;
    }
    88% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media screen and (min-width: 36em) {
    .image_0,
    .image_1,
    .image_2 {
      display: none;
    }

    .image_3 {
      display: block;
      background-image: url(${project4});
      animation-delay: 0s;
    }

    .image_4 {
      display: block;
      background-image: url(${project5});
      animation-delay: 8s;
    }

    .image_5 {
      display: block;
      background-image: url(${project6});
      animation-delay: 16s;
    }
  }

  @media screen and (min-width: 75em) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: clamp(15.5rem, 20vw, 20rem) clamp(25rem, 20vw, 30rem);

    gap: 0.5rem;
    padding: 0 1.5rem;
    margin-bottom: 10rem;
    min-height: 100%;

    background-color: transparent;

    p {
      align-self: flex-start;
      color: var(--color-text-dark);
      line-height: 1.2;
      font-size: 20px;
      text-align: start;

      max-width: 58ch;
      grid-column: 6 / 13;
      grid-row: 1 / 2;

      padding: 0 0 0 2.5rem;
    }

    .img__filter {
      display: none;
    }

    .img_cont {
      position: static;
      z-index: 2;

      width: 100%;
      height: 100%;

      -webkit-animation: none;
      animation: none;
    }

    .image_0 {
      display: block;

      background-image: url(${project1});
      background-position: 10% 0;

      grid-column: 1 / 3;
      grid-row: 1 / 3;
    }

    .image_2 {
      display: block;

      background-image: url(${project6});

      grid-column: 3 / 6;
      grid-row: 1 / 2;
    }

    .image_3 {
      display: block;

      background-image: url(${project2});
      grid-column: 3 / 5;
      grid-row: 2 / 3;
    }

    .image_4 {
      display: block;

      background-image: url(${project4});
      grid-column: 5 / 9;
      grid-row: 2 / 3;
    }

    .image_5 {
      display: block;

      background-image: url(${project5});
      grid-column: 9 / 13;
      grid-row: 2 / 3;
    }
  }
`;

const Projects = () => {
  const { t } = useTranslation();
  const images = [project1, project2, project3, project4, project5, project6];

  return (
    <>
      <AnchorScrollToPosition id="projects" />
      <ProjectsContainer>
        <TitleContainer>
          <span />
          <h2>{t("proyectos")}</h2>
          <span />
        </TitleContainer>
        <Content>
          <p>
            <Trans i18nKey="projectText">
              Para llevar a cabo el diseño, selección y ejecución de los
              proyectos, tengo en cuenta las preferencias personales de cada
              cliente, paleta de colores preferida, estilo, hábitos y uso del
              ambiente en cuestión. <br />
              <br /> Tomando estos puntos, me inspiro en las tendencias, los
              materiales y ubicación de cada elemento en el espacio para lograr
              la ambientación perfecta.
            </Trans>
          </p>
          <div className="img__filter" />
          {images.map((image, index) => (
            <div key={index} className={`image_${index} img_cont`} />
          ))}
        </Content>
      </ProjectsContainer>
    </>
  );
};

export default Projects;
