import styled from "styled-components";

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  margin-block: 4rem 2rem;

  span {
    border-bottom: 2px solid var(--base-c-green);
    width: 100%;
  }

  h2 {
    margin: 2rem;
  }

  @media screen and (min-width: 48em) {
    margin: 4rem 6rem 2rem;

    h2 {
      font-size: var(--fs-400);
      margin: 2rem 4rem;
    }

    span {
      border-bottom: 3px solid var(--base-c-green);
    }
  }
`;

export const AnchorScrollToPosition = styled.a`
  display: block;
  position: relative;
  top: -8.7rem;
  visibility: hidden;

  @media screen and (max-width: 62em) {
    display: block;
    position: relative;
    top: -7.6rem;
    visibility: hidden;
  }
`;
