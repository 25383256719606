import { portrait } from "../assets/images/index";
import styled from "styled-components";
import {
  AnchorScrollToPosition,
  TitleContainer,
} from "../assets/styled-components/global";
import { Trans, useTranslation } from "react-i18next";

const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 4rem;
  padding-top: 2rem;
  width: 100%;

  background: linear-gradient(
    180deg,
    rgba(148, 138, 132, 1) 0%,
    rgba(203, 193, 185, 1) 15%
  );

  box-shadow: 0 -0.7rem 1rem rgb(148, 138, 132);

  @media screen and (min-width: 62em) {
    background: var(--color-background);
    box-shadow: none;

    section {
      display: flex;
      justify-content: space-evenly;
      column-gap: 3rem;

      margin-bottom: 4rem;
      padding: 3rem;
    }
  }
`;

const PortraitImage = styled.img`
  width: clamp(25rem, 75vw, 34.5rem);
  max-height: 440px;

  margin: auto;
  margin-bottom: 4rem;

  border-radius: 1.5rem;
  box-shadow: 0 0 8px var(--base-box-shadow);

  @media screen and (min-width: 62em) {
    align-self: center;

    margin: 0;
    box-shadow: 0 0 8px var(--base-c-green);
  }
`;

const TextContainer = styled.div`
  display: grid;
  place-items: center;

  background-color: var(--base-c-green-soft);
  padding: 4rem;

  p {
    font-size: 18px;
    max-width: 65ch;
    line-height: 1.3;
    color: var(--color-text-light);
  }

  span {
    font-weight: 700;
  }

  @media screen and (min-width: 62em) {
    border-radius: 8px;
    padding: 3rem 4rem;

    box-shadow: 0 0 3px var(--base-c-green-soft);

    p {
      font-size: 20px;

      max-width: 55ch;
      line-height: 1.4;
    }
  }
`;

const About = () => {
  const { t } = useTranslation();

  return (
    <>
      <AnchorScrollToPosition id="about" />
      <AboutContainer>
        <TitleContainer>
          <span />
          <h2> {t("nosotros")} </h2>
          <span />
        </TitleContainer>
        <section>
          <PortraitImage src={portrait} />
          <TextContainer>
            <p>
              <Trans i18nKey="aboutText">
                Soy <span>Cecilia D'Atri</span>, Interiorista y apasionada por
                la decoración, el orden, la estética y la armonía visual. <br />
                <br /> Disfruto escuchar la necesidad de cada uno de mis
                clientes, conocerlos, captar su personalidad y sus preferencias
                para poder acertar a la hora de asesorarlos. Crear un vínculo
                con ellos hace que todo fluya y se sientan felices al terminar
                el proyecto. Ver ese momento es sumamente gratificante. <br />
                <br /> Trabajo en conjunto con profesionales de todos los rubros
                para poder brindar un servicio completo. Ya sean de reforma,
                construcción, arquitectos, pintores, electricistas, fontaneros,
                como diseñadores de marcas comerciales y diseñadores graficos.
              </Trans>
            </p>
          </TextContainer>
        </section>
      </AboutContainer>
    </>
  );
};

export default About;
