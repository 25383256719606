import { BsInstagram } from "react-icons/bs";

export const menuItems = [
  {
    title: "nosotros",
    ref: "#about",
  },
  {
    title: "servicios",
    ref: "#services",
  },
  {
    title: "proyectos",
    ref: "#projects",
  },
  {
    title: "contacto",
    ref: "#contact",
  },
  {
    image: <BsInstagram />,
    ref: "https://www.instagram.com/cecidatri.interiores",
  },
];
