import { useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import emailjs from "@emailjs/browser";

import { BsWhatsapp, BsInstagram } from "react-icons/bs";
import { TbLocation } from "react-icons/tb";
import { HiOutlineMail } from "react-icons/hi";
import styled from "styled-components";

import { contact } from "../assets/images/index";
import {
  AnchorScrollToPosition,
  TitleContainer,
} from "../assets/styled-components/global";

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 2rem;

  padding-bottom: 5rem;

  width: 100%;

  background-color: var(--color-background-dark);

  section {
    display: grid;
    justify-items: center;
    align-items: end;

    gap: 5rem;

    div:nth-child(1) {
      align-self: start;
    }
  }

  @media screen and (min-width: 48em) {
    section {
      grid-template-rows: 1fr 1fr;
      grid-template-columns: repeat(12, 1fr);

      gap: 0;
      row-gap: 2rem;
      padding: 0 4rem;
    }
  }

  @media screen and (min-width: 62em) {
    section {
      padding: 0 6rem;
    }
  }

  @media screen and (min-width: 75em) {
    background-color: var(--color-background);
    padding-bottom: 7rem;

    section {
      padding: 0 8rem;
    }
  }
`;

const ContactDesc = styled.div`
  display: flex;
  align-items: center;

  grid-row: 1 / 2;

  gap: 1.5rem;
  padding: 0 2.5rem;

  color: var(--color-text-dark);
  text-align: center;

  span {
    font-weight: 700;
    text-transform: uppercase;
  }

  img {
    height: 91px;
    width: 91px;
  }

  p {
    max-width: 45ch;
  }

  @media screen and (min-width: 48em) {
    grid-column: 1 / 6;
    padding: 0;

    flex-direction: column;
    justify-content: center;
  }

  @media screen and (min-width: 62em) {
    grid-column: 1 / 5;

    p {
      max-width: 30ch;
    }

    img {
      height: 96px;
      width: 96px;
    }
  }
`;

const ContactDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  grid-row: 3 / 4;

  a,
  p {
    font-size: var(--fs-100);
    font-weight: 500;
    letter-spacing: 0.075rem;
    color: var(--color-text-dark);
  }

  a {
    text-decoration: underline;
  }

  div {
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }

  svg {
    height: 28px;
    width: 28px;
  }

  @media screen and (min-width: 48em) {
    grid-row: 2 / 3;
    grid-column: 1 / 6;
  }

  @media screen and (min-width: 62em) {
    grid-column: 1 / 5;
    gap: 2rem;

    svg {
      height: 30px;
      width: 30px;
    }
  }
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;

  grid-row: 2 / 3;

  width: clamp(25rem, 80vw, 55rem);

  div {
    display: flex;
    flex-direction: column;
  }

  h5 {
    margin-bottom: 1.75rem;
  }

  label {
    font-size: var(--fs-100);
    font-weight: 500;
    color: var(--color-text-dark);

    padding-left: 0.15rem;

    &::first-letter {
      text-transform: capitalize;
    }
  }

  input,
  textarea {
    padding: 0.75rem 1rem;
    margin-block: 0.35rem 1.25rem;

    outline: none;
    border: 0.1rem solid var(--color-border);
    border-radius: 0.5rem;

    font-size: var(--fs-100);
    color: var(--color-text-dark);
    background-color: var(--base-c-white);

    &:focus {
      outline: none;
      border: 0.1rem solid var(--color-border-focus);
      box-shadow: 0 0 0.3rem var(--color-border-focus);
    }
  }

  textarea {
    resize: none;
  }

  .border-error {
    border: 2px solid red;
  }

  .footer__error {
    background-color: rgba(255, 102, 102, 0.5);
    padding: 0.5rem 1rem;
    margin-block: 1rem;
    border-radius: 7px;

    color: var(--base-c-white);
  }

  button {
    width: 75%;
    align-self: center;

    padding-block: 1rem;
    margin-top: 1rem;
    border: none;
    border-radius: 0.7rem;

    background-color: var(--base-c-green);
    color: var(--color-text-light);

    font-size: var(--fs-100);
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.2rem;

    cursor: pointer;

    &:active {
      background-color: var(--base-c-green-soft);
      box-shadow: 0 0.4rem 0.3rem var(--base-c-green);
      transform: translateY(4px);
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0.3rem var(--base-c-green);
    }
  }

  h4 {
    align-self: center;
    max-width: 30ch;
    color: var(--color-heading-dark);
    padding: 2rem;
  }

  @media screen and (min-width: 48em) {
    grid-row: 1 / 3;
    grid-column: 7 / 13;

    display: grid;
    grid-template-column: 1fr 1fr;
    column-gap: 1rem;

    h5 {
      grid-column: 1 / 3;
    }

    div:nth-child(2) {
      grid-column: 1 / 2;
    }

    div:nth-child(3) {
      grid-column: 2 / 3;
    }

    div:nth-child(4) {
      grid-column: 1 / 3;
    }

    div:nth-child(5) {
      grid-column: 1 / 3;
    }

    button {
      grid-column: 1 / 3;
      justify-self: center;
    }

    max-width: 60vw;
  }

  @media screen and (min-width: 62em) {
    grid-column: 6 / 13;

    width: clamp(25rem, 100%, 55rem);
  }

  @media screen and (min-width: 75em) {
    background: var(--base-c-green);
    padding: 2rem 1.75rem;
    border-radius: 0.8rem;

    h5 {
      color: var(--color-heading-light);
    }

    h4 {
      color: var(--color-heading-light);
    }

    label {
      color: var(--color-text-light);
    }

    button {
      background-color: var(--color-background);
      color: var(--color-text-dark);
      font-weight: 700;

      &:active {
        background-color: var(--color-background-dark);
        box-shadow: 0 0.4rem 0.3rem var(--color-background);
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 0.3rem var(--color-background);
      }
    }
  }
`;

const serviceID = process.env.REACT_APP_SERVICE_ID;
const templateID = process.env.REACT_APP_TEMPLATE_ID;
const publicKey = process.env.REACT_APP_PUBLIC_KEY;

const Contact = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const form = useRef();

  const sendEmail = (e) => {
    // debugger;
    e.preventDefault();
    setLoading(true);

    emailjs.sendForm(serviceID, templateID, form.current, publicKey).then(
      () => {
        setLoading(false);
        setIsFormSubmitted(true);
      },
      (error) => {
        alert(error.text);
      }
    );
  };

  return (
    <>
      <AnchorScrollToPosition id="contact" />
      <ContactContainer>
        <TitleContainer>
          <span />
          <h2>{t("contacto")}</h2>
          <span />
        </TitleContainer>
        <section>
          <ContactDesc>
            <img src={contact} alt="contact_icon" />
            <p>
              <Trans i18nKey="contactDesc">
                Si seguis dando vueltas con la deco de tu casa,
                <span>escribime</span> que te ayudo
              </Trans>
            </p>
          </ContactDesc>
          <ContactDetails>
            <div>
              <BsWhatsapp />
              <a
                href="https://wa.me/message/4MQZCCG54U7AA1"
                target="_blank"
                rel="noreferrer"
              >
                <p>(+34) 651 01 52 44</p>
              </a>
            </div>
            <div>
              <HiOutlineMail />
              <a href="mailto:info@cecidatri.com"
              rel="noreferrer">info@cecidatri.com</a>
            </div>
            <div>
              <BsInstagram />
              <a
                href="https://www.instagram.com/cecidatri.interiores"
                target="_blank"
                rel="noreferrer"
              >
                <p>@cecidatri.interiores</p>
              </a>
            </div>
            <div>
              <TbLocation />
              <p>
                Zona Canovas <br />
                Valencia - España
              </p>
            </div>
          </ContactDetails>
          {!isFormSubmitted ? (
            <ContactForm onSubmit={sendEmail} ref={form}>
              <h5> {t("contactFormTitle")} </h5>
              <div>
                <label htmlFor="name">{t("name")}</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder={t("namePlaceholder")}
                  required
                />
              </div>
              <div>
                <label htmlFor="telefono">{t("telefono")}</label>
                <input
                  type="text"
                  name="telefono"
                  id="phone"
                  placeholder="+34 123 45 67 89"
                />
              </div>
              <div>
                <label htmlFor="email">email*</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder={t("emailPlaceholder")}
                  required
                />
              </div>
              <div>
                {" "}
                <label htmlFor="message">{t("deja")}</label>
                <textarea
                  name="message"
                  id="message"
                  rows="5"
                  placeholder={t("dejaPlaceholder")}
                  required
                ></textarea>
              </div>

              <button type="submit" disabled={loading}>
                {!loading ? t("enviar") : t("sending")}
              </button>
            </ContactForm>
          ) : (
            <h4>
              <Trans i18nKey="graciasEnviar">
                ¡Gracias por ponerse en contacto! <br />
                Nos pondremos en contacto contigo lo antes posible.
              </Trans>
            </h4>
          )}
        </section>
      </ContactContainer>
    </>
  );
};

export default Contact;
