import {
  decoracion,
  interiorismo,
  personalizad,
  shopper,
} from "../images/index";

export const serviciosItems = [
  {
    title: "interiorismo",
    image: interiorismo,
    text: "Si quieres <span>reformar</span> y no sabes por donde comenzar, te ayudo a plantear la mejor opción en base a tus necesidades, <span>realizar un plano</span> de lo existente y uno de la vivienda o comercio reformado. Trabajamos también con <span>imágenes 3D</span> para que veas el resultado final.",
    titleKey: "interiorismo",
    textKey: "intText",
  },
  {
    title: "decoracion",
    image: decoracion,
    text: "Los <span>elementos decorativos</span> son los que terminan de darle vida al proyecto. <span>Te asesoro</span> para escoger los colores, muebles, disposición y los elementos indicados para el estilo e imagen que quieras crear en tus espacios. También puedo ayudarte a <span>re-organizar</span> mobiliario y espacios.",
    titleKey: "decoracion",
    textKey: "decText",
  },
  {
    title: "personal shopper",
    image: shopper,
    text: "Te <span>acompaño</span> en el proceso de <span>compra</span> de los elementos que comprendan el proyecto desarrollado, o bien <span>realizamos la compra, el traslado y el montaje</span> por ti para que no pases por ese momento de estrés y llegues a tu nuevo <span>hogar o comercio</span> completamente hecho a tu medida.",
    titleKey: "persoShopper",
    textKey: "psText",
  },
  {
    title: "personalizados",
    image: personalizad,
    text: "Si hay algún servicio relativo, que no esté descrito, consúltame sin compromiso sobre el mismo.",
    titleKey: "personalizados",
    textKey: "persoText",
  },
];
