import styled from "styled-components";
import {
  TitleContainer,
  AnchorScrollToPosition,
} from "../assets/styled-components/global";
import { serviciosItems } from "../assets/data/servicios-items";
import { useTranslation } from "react-i18next";

const ServicesContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 4rem;

  width: 100%;
  padding-bottom: 4rem;

  background: var(--color-background);

  section {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    div:nth-child(4) {
      text-align: center;
      img {
        display: none;
      }
    }
  }

  @media screen and (min-width: 48em) {
    section {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr auto;

      padding: 0 4rem;
      gap: 4rem;

      div:nth-child(4) {
        grid-area: 2 / 2;

        div {
          padding: 2rem;
          justify-content: center;
        }

        img {
          display: block;
        }
      }
    }
  }

  @media screen and (min-width: 62em) {
    section {
      grid-template-columns: 1fr 1fr 1fr;
      padding: 0 6rem;

      div:nth-child(4) {
        grid-column: 1 / 4;
        grid-row: 2/ 3;

        background-color: transparent;
        box-shadow: none;

        img {
          display: none;
        }

        h4 {
          display: none;
        }

        .text__div {
          font-size: 22px;
          font-weight: 500;
        }
      }
    }
  }
`;

const Item = styled.div`
  h4 {
    background-color: var(--color-background-dark);
    padding: 2rem 0;
  }

  .text__div {
    color: var(--color-text-dark);
    font-size: var(--fs-100);
    line-height: 1.4;

    padding: 4rem 3.5rem 2rem;
  }

  span {
    font-weight: 700;
  }

  @media screen and (min-width: 48em) {
    background-color: var(--base-c-white-mute);
    box-shadow: 0 0 5px 0.5px var(--base-box-shadow);
    border-radius: 5px;

    display: flex;
    flex-direction: column;

    h4 {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    .text__container {
      display: flex;
      align-items: center;
      height: 100%;
    }

    .text__div {
      padding: 2.5rem;

      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
`;

const Services = () => {
  const { t } = useTranslation();
  return (
    <>
      <AnchorScrollToPosition id="services" />
      <ServicesContainer>
        <TitleContainer>
          <span />
          <h2>{t("servicios")}</h2>
          <span />
        </TitleContainer>
        <section>
          {serviciosItems.map((item) => (
            <Item key={`item ${item.title}`}>
              <h4> {t(item.titleKey)} </h4>
              {item.image && <img src={item.image} alt={`${item.title} img`} />}
              <div className="text__container">
                <div
                  dangerouslySetInnerHTML={{ __html: t(item.textKey) }}
                  className="text__div"
                />
              </div>
            </Item>
          ))}
        </section>
      </ServicesContainer>
    </>
  );
};

export default Services;
