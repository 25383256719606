import styled from "styled-components";

import {
  About,
  Contact,
  Hero,
  Navbar,
  Projects,
  Services,
  FloatingButton,
  Footer,
} from "./Components/index";

const Main = styled.main`
  display: flex;
  align-items: center;
  flex-direction: column;

  max-width: 128rem;
`;

const App = () => {
  return (
    <Main>
      <FloatingButton />
      <Navbar />
      <Hero />
      <About />
      <Services />
      <Projects />
      <Contact />
      <Footer />
    </Main>
  );
};

export default App;
