import styled from "styled-components";
import { BsWhatsapp } from "react-icons/bs";
import { IoIosArrowUp } from "react-icons/io";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const FloatingBtnContainer = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 20;

  width: 100%;
  max-width: 128rem;

  a {
    display: grid;
    place-items: center;
    position: absolute;

    border-radius: 50%;

    cursor: pointer;
    transition: transform 0.2s ease-in, opacity 0.2s ease-in,
      background-color 0.2s ease-in;

    span {
      position: absolute;

      visibility: hidden;
      padding: 0.5rem 0.75rem;

      background-color: var(--base-c-green);
      color: var(--color-text-light);

      font-size: 14px;
      text-align: center;

      border-radius: 6px;
    }

    &:hover {
      transform: scale(1.1);

      span {
        visibility: visible;
      }
    }
  }

  svg {
    height: 24px;
    width: 24px;
    color: var(--color-text-light);
  }
`;

const WhatsappBtn = styled.a`
  bottom: 25px;
  left: 28px;
  width: 56px;
  height: 56px;

  background-color: #25d366;
  box-shadow: 0 0 2px 1px #25d366;

  span {
    top: 13px;
    left: 70px;
    white-space: nowrap;

    box-shadow: 0 0 2px 1px var(--base-c-green);

    ::after {
      content: " ";
      position: absolute;
      top: 50%;
      right: 100%;

      margin-top: -5px;
      border-width: 6px;
      border-style: solid;
      border-color: transparent var(--base-c-green) transparent transparent;
    }
  }

  @media screen and (max-width: 36em) {
    bottom: 15px;
    left: 15px;

    span {
      display: none;
    }
  }
`;

const BackToTopBtn = styled.a`
  bottom: 25px;
  right: 28px;
  width: 44px;
  height: 44px;

  background-color: var(--base-c-green);
  border: 1px solid var(--base-c-green-soft);
  box-shadow: 0 0 2px 1px var(--base-c-green-soft);

  span {
    top: 7px;
    right: 57px;
    white-space: nowrap;

    box-shadow: 0 0 2px 1px var(--base-c-green);

    ::after {
      content: " ";
      position: absolute;
      top: 50%;
      left: 100%;

      margin-top: -5px;
      border-width: 6px;
      border-style: solid;
      border-color: transparent transparent transparent var(--base-c-green);
    }
  }

  &:hover {
    background-color: var(--base-c-green);
  }

  @media screen and (max-width: 36em) {
    bottom: 15px;
    right: 15px;

    span {
      display: none;
    }
  }
`;

const FloatingButton = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 200) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const showBtnStyle = { visibility: "visible", opacity: 1 };
  const hideBtnStyle = { visibility: "hidden", opacity: 0 };

  window.addEventListener("scroll", toggleVisible);

  const backToTopStyle = visible ? showBtnStyle : hideBtnStyle;

  return (
    <FloatingBtnContainer>
      <WhatsappBtn
        href="https://wa.me/message/4MQZCCG54U7AA1"
        target="_blank"
        rel="noreferrer"
      >
        <BsWhatsapp />
        <span>{t("whatsapp")}</span>
      </WhatsappBtn>
      <BackToTopBtn onClick={scrollToTop} style={backToTopStyle}>
        <IoIosArrowUp />
        <span>{t("backTT")}</span>
      </BackToTopBtn>
    </FloatingBtnContainer>
  );
};

export default FloatingButton;
