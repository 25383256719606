import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useMediaQuery } from "@mui/material";
import { HiMenuAlt4, HiX } from "react-icons/hi";
import styled from "styled-components";

import { menuItems } from "../assets/data/menu-items";
import { logo, eng, esp } from "../assets/images/index";

const lngs = {
  en: { nativeName: "English", icon: eng },
  es: { nativeName: "Spanish", icon: esp },
};

const NavContainer = styled.nav`
  position: fixed;
  top: 0;
  z-index: 100;

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  background-color: var(--base-c-white-faded);
  box-shadow: 0 3px 5px -5px var(--base-c-black);
  backdrop-filter: blur(4px);

  padding: 1rem 1.75rem 1rem 1rem;

  .topbar_button {
    height: 28px;
    width: 28px;

    color: var(--base-c-green);
    cursor: pointer;
  }

  @media screen and (min-width: 62em) {
    padding: 1.25rem 3.5rem 1.25rem 2.5rem;
    top: 1rem;

    width: clamp(15rem, 95vw, 124rem);
    border-radius: 8px;
    box-shadow: 0 0 3px 3px var(--base-box-shadow);

    .topbar_button {
      display: none;
    }
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;

  gap: 1rem;
  line-height: 1;

  img {
    height: 5.8rem;
    width: 5.8rem;
  }

  p {
    font-size: clamp(1.2em, calc(1.2em + 0.390625vw), 1.6em);
    font-weight: 300;

    color: var(--color-text-dark);
    letter-spacing: 0.05em;
    padding-left: 0.15rem;
  }

  @media screen and (min-width: 62em) {
    gap: 1.5rem;

    img {
      height: 6.2rem;
      width: 6.2rem;
    }
  }
`;

const NavbarMenu = styled.ul`
  display: flex;
  align-items: flex-end;
  gap: calc(3rem + 0.390625vw);

  li {
    display: flex;
    flex-direction: column;
    align-items: center;

    div {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      margin-bottom: 3px;

      transition: 0.2s background-color ease-in;
    }

    &:hover {
      div {
        background-color: var(--base-c-green);
      }
    }

    a {
      font-size: var(--fs-100);
      font-weight: 600;
      text-transform: uppercase;

      color: var(--color-text-dark);
    }
  }

  .inst_logo {
    line-height: 0;

    svg {      
      height: 22px;
      width: 22px;

      margin-bottom: .2rem;
      color: color: var(--color-text-dark);
    }
  }

  button {
    height: 16px;
    width: 24px;

    padding: 0;
    outline: none;
    border: none;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }

    &:disabled {
      display: none;
    }
  }

  @media screen and (max-width: 62em) {
    display: none;
  }
`;

const TopbarContainer = styled.nav`
  width: 100%;

  background-color: var(--color-background);

  ul {
    display: flex;
    flex-direction: column;

    gap: 3rem;
    padding-block: 5rem 2.5rem;

    padding-left: clamp(30px, 8vw, 50px);
    padding-right: 2rem;

    .closing__tab {
      position: absolute;
      top: 18px;
      right: 18px;
      align-self: flex-end;
      height: 32px;
      width: 32px;

      color: var(--color-text-dark);

      cursor: pointer;
    }
  }

  font-size: var(--fs-100);
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;

  a {
    color: var(--color-text-dark);
    text-decoration: none;

    svg {
      height: 28px;
      width: 28px;
    }
  }

  button {
    height: 18px;
    width: 26px;

    padding: 0;
    outline: none;
    border: none;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }

    &:disabled {
      display: none;
    }
  }

  @media screen and (min-width: 62em) {
    display: none;
  }
`;

const Navbar = () => {
  const { t, i18n } = useTranslation();

  const largeScreen = useMediaQuery("(min-width: 992px)");
  const [topbarIsOpen, setTopbarIsOpen] = useState(false);

  // Function to close drawer
  const closeDrawer = () => {
    setTopbarIsOpen(false);
  };

  // Function to open Drawer
  const openDrawer = () => {
    setTopbarIsOpen(true);
  };

  const navMenu = menuItems.map((item) => (
    <li key={item.title ? item.title : "inst logo"} onClick={closeDrawer}>
      {item.title && (
        <>
          <div />
          <a href={item.ref} className="menu_item">
            {t(item.title)}
          </a>
        </>
      )}
      {item.image && (
        <a
          href={item.ref}
          target="_blank"
          rel="noreferrer"
          className="inst_logo"
        >
          {item.image}
        </a>
      )}
    </li>
  ));

  useEffect(() => {
    if (largeScreen) {
      closeDrawer();
    }
  }, [largeScreen]);

  return (
    <NavContainer>
      <LogoContainer>
        <img src={logo} alt="logo" />
        <div>
          <h2>ceci d'atri</h2>
          <p>{t("logoHeader")}</p>
        </div>
      </LogoContainer>

      <NavbarMenu>
        {navMenu}
        <li style={{ paddingBottom: "5px" }}>
          {Object.keys(lngs).map((lng) => (
            <button
              type="submit"
              title={lngs[lng].nativeName}
              key={lng}
              onClick={() => i18n.changeLanguage(lng)}
              disabled={i18n.resolvedLanguage === lng}
            >
              <img src={lngs[lng].icon} alt={lngs[lng].nativeName} />
            </button>
          ))}
        </li>
      </NavbarMenu>

      <HiMenuAlt4 className="topbar_button" onClick={openDrawer} />
      <SwipeableDrawer
        anchor="top"
        open={topbarIsOpen}
        onClose={closeDrawer}
        onOpen={openDrawer}
      >
        <Topbar closeDrawer={closeDrawer} navMenu={navMenu} />
      </SwipeableDrawer>
    </NavContainer>
  );
};

export default Navbar;

const Topbar = ({ closeDrawer, navMenu }) => {
  const { i18n } = useTranslation();
  return (
    <TopbarContainer>
      <ul>
        <HiX className="closing__tab" onClick={closeDrawer} />
        {navMenu}
        {Object.keys(lngs).map((lng) => (
          <button
            type="submit"
            key={lng}
            onClick={() => i18n.changeLanguage(lng)}
            disabled={i18n.resolvedLanguage === lng}
          >
            <img src={lngs[lng].icon} alt={lngs[lng].nativeName} />
          </button>
        ))}
      </ul>
    </TopbarContainer>
  );
};
